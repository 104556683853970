@use '../../globals/Fonts';
@use '../../globals/Colors';
@use '../../globals/Defaults.scss';


.sliderblock {
  cursor: pointer;
  width: 160px;
  height: 150px;
  border: 2px solid #E5E5E5;

  @media (min-width: 576px) {
    width: 160px;
    height: 160px;
  }

  @media (min-width: 1450px) {
    width: 225px;
    height: 200px;
  }

  @media (min-width: 2000px) {
    width: 300px;
    height: 300px;
  }

  &__logo {
    width: 50%;
    height: 50%;
    display: flex;
    align-items: center;

    >img {
      width: 100%;
      height: auto;
    }
  }

  &__cta {
    position: absolute;
    bottom: 20px;
    padding: 5px 20px;
    font-size: 12px;
    transition-duration: 0.3s;

    @media (min-width: 576px) {
      padding: 6px 20px;
      font-size: 12px;
    }

    @media (min-width: 1450px) {
      padding: 6px 40px;
      font-size: 14px;
    }
  }
}
