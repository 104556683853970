@use '../../globals/Fonts';
@use '../../globals/Colors';
@use '../../globals/Defaults.scss';


.vendorContainer {
  padding: 0 !important;

  &__title {
    font-size: 20px;
    text-align: center;
  }

  &__campaignInfo {
    text-align: center;

    &__cta {
      text-decoration: none;
      transition-duration: 0.3s;
      padding: 5px 25px;
      font-size: 12px;

      @media (min-width: 1450px) {
        font-size: 14px;
        padding: 6px 40px;
      }
    }
  }
}
