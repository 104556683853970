@use "../../globals/Fonts";
@use "../../globals/Colors.scss";
@use "../../globals/Defaults.scss";

.footer {
  height: auto;
  min-height: 25vh;
  width: 100%;
  border-top: 1px solid black;

  &__top {
    height: auto;
    min-height: 50px;

    &__copy {
      height: 100%;
      font-size: 32px;
      line-height: 1;
    }

    &__logo {
      width: auto;
      height: 30px;
    }

    &__icon {
      width: 190px;
      height: 130px;
      opacity: 0.2;
      background: url("/assets/m_icon.png");
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }

  &__middle {
    height: auto;
    font-size: 1em;
    line-height: 0.5;
    margin-bottom: 5px;
    padding: 20px 0px;

    &__divider {
      border-bottom: 1px solid Colors.$white;
    }

    &__linkContainer {
      & .row>div {
        @include Defaults.flexCenter;
      }

      .navlink {
        text-decoration: none;
        padding-bottom: 5px;
      }

      &--cookieButton {
        color: Colors.$turquoise;
        text-decoration: none;
        padding: 10px 10px;
        border: 1px solid Colors.$turquoise;
        transition-duration: 0.3s;
        background: none;

        &:hover {
          background: Colors.$turquoise;
          color: Colors.$white;
        }
      }
    }

    &__socialCopy {
      width: auto;
    }

    &__socialContainer {
      height: 15px;
      width: 15px;
      margin: 5px;
      padding: 15px;
      border-radius: 50%;
      transition-duration: 0.3s;

      &__socialIcon {
        transform: translate(-50%, -50%);
        transition-duration: 0.3s;
      }
    }
  }

  &__bottom {
    height: auto;
    font-size: 0.8rem;

    &__linkContainer {
      padding: 5px 0px;
    }

    &__copyright {
      margin-top: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-top: 1px solid Colors.$white;
    }
  }
}
