@font-face {
  font-family: 'MarsCentra';
  src:
    url('../assets/fonts/MarsCentra-Book.woff2') format('woff2'),
    url('../assets/fonts/MarsCentra-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MarsCentra';
  src:
    url('../assets/fonts/MarsCentra-Bold.woff2') format('woff2'),
    url('../assets/fonts/MarsCentra-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MarsCentra';
  src:
    url('../assets/fonts/MarsCentra-Extrabold.woff2') format('woff2'),
    url('../assets/fonts/MarsCentra-Extrabold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

$fontFace: 'MarsCentra';

@mixin fontDefault {
  font-family: $fontFace;
  font-weight: 300;
}

;

@mixin fontBold {
  font-family: $fontFace;
  font-weight: 700;
}

;

@mixin fontExtraBold {
  font-family: $fontFace;
  font-weight: 800;
}

;
