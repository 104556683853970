@use './Fonts';
@use './Colors';

* {
  box-sizing: border-box;
}

div[class*="row"] {
  padding: 0 !important;
}

@mixin d-flex {
  display: flex !important;
}

@mixin justify-content-center {
  justify-content: center !important;
}

@mixin flexCenter {
  @include d-flex;
  @include justify-content-center;
}
