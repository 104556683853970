@use '../../globals/Fonts';
@use '../../globals/Colors';
@use '../../globals/Defaults.scss';


.hero {
  height: 100%;

  &__copy {
    width: 90%;
    color: Colors.$white;
    line-height: 1;
    font-size: 25px;

    @media (min-width: 720px) {
      font-size: 45px;
    }

    @media (min-width: 1200px) {
      font-size: 60px
    }
  }

  &__image {
    height: 25vh;
    width: auto;

    @media (min-width: 720px) {
      height: 40vh;
      width: auto;
    }

    @media (min-width: 1200px) {
      height: auto;
      width: 100%;
    }
  }
}
