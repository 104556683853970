@use '../../globals/Fonts';
@use '../../globals/Colors';
@use '../../globals/Defaults.scss';

.header {
  width: 100%;
  height: auto;
  min-height: 5vh;
  border-bottom: 1px solid Colors.$lightGrey;

  &__logo {
    width: auto;
    height: 60px;
  }
}
